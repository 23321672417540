export const cartMixin = {
    data() {
        return {
            cartItems: [],
        };
    },
    created() {
        // Retrieve cart items from cookie if available
        const storedCartItems = this.getCookie('cartItems');
        this.cartItems = storedCartItems ? JSON.parse(storedCartItems) : [];
    },
    methods: {
        addToCart(post) {
            const newItem = { id: post.id }; // Create the item object
            if (!this.isInCart(newItem)) {
                this.cartItems.push(newItem);
                this.updateCookie();
            }
        },
        removeFromCart(item) {
            this.cartItems = this.cartItems.filter((cartItem) => {
                return cartItem.id !== item.id;
            });
            this.updateCookie();
            this.updateCartCount();
        },
        isInCart(item) {
            return this.cartItems.some((cartItem) => {
                return cartItem.id === item.id;
            });
        },
        updateCookie() {
            this.setCookie('cartItems', JSON.stringify(this.cartItems), 7);
        },
        addToOrRemoveFromCart(post) {
            if (this.isInCart(post)) {
                this.removeFromCart(post);
            } else {
                this.addToCart(post);
            }
            this.updateCartCount();
        },
        updateCartCount() {
            const cartCount = this.cartItems.length;
            // Update the cart count in the template
            const cartCountElement = document.querySelector('.count_items');
            if (cartCountElement) {
                cartCountElement.textContent = cartCount;
            }
        },
        clearCart() {
            this.cartItems = [];
            this.updateCookie();
            this.updateCartCount();
        },
        isCartEmpty() {
            return this.cartItems.length === 0;
        },
        sendEmail() {
            const path_domain = window.location.origin;
            // Send the email and concatenate the array of ids with -
            let ids = this.cartItems.map((item) => item.id).join('-');
            let emailBody = "Please find assets :\n\n";
            emailBody += path_domain + "/download/mail?pictures=" + ids;
            const mailtoLink = `mailto:?body=${encodeURIComponent(emailBody)}`;
            // Open the mail client
            window.location.href = mailtoLink;
        },

        setCookie(name, value, days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            const expires = `expires=${date.toUTCString()}`;
            document.cookie = `${name}=${value};${expires};path=/`;
        },
        getCookie(name) {
            const cookieName = `${name}=`;
            const decodedCookie = decodeURIComponent(document.cookie);
            const cookieArray = decodedCookie.split(';');
            for (let i = 0; i < cookieArray.length; i++) {
                let cookie = cookieArray[i];
                while (cookie.charAt(0) === ' ') {
                    cookie = cookie.substring(1);
                }
                if (cookie.indexOf(cookieName) === 0) {
                    return cookie.substring(cookieName.length, cookie.length);
                }
            }
            return '';
        },
    },
};
