/**
 * File: app.js
 * Description: This file contains the implementation of our main JavaScript scripts.
 * We can import all the needed JavaScript modules.
 */

// Import the createApp function from the Vue library
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import CartCounterComponent from './components/Pages/GetCartCounterComponent.vue';
import GetSliderPostsComponent from './components/Posts/GetSliderPostsComponent.vue';
// Import the jQuery library and assign it to the global variables $ and jQuery
window.$ = window.jQuery = require('jquery');


/* End Import all Vue.js components */

/* Start Import all JavaScript modules */
// Import jQueryUI if needed
// require('jquery-ui')
// Import CookieConsent if needed
require('vanilla-cookieconsent');
// Import ImagesLoaded if needed
require('imagesloaded');
// Import Masonry if needed
require('masonry-layout');
// Import Isotope if needed
// require('isotope-layout')


import Swiper from 'swiper';
import '../js/modules/headerNav';
import '../js/modules/slider';
import '../js/modules/filters';



/* End Import all JavaScript modules */


/* Start Import Alpine.js */
// Import Alpine.js and assign it to the global variable Alpine
import Alpine from 'alpinejs';


// Posts masonry - a déplacer
// let $masonryPostsContainer = $('.masonry-posts-container');
// let masonrycontainer = $masonryPostsContainer.masonry({
//     itemSelector: '.post-item',
//     masonry: {
//         columnWidth: 302,
//         horizontalOrder: true,
//         percentPosition: false,
//         originLeft: false,
//         resize: true,
//         isFitWidth: false,
//         isAnimated: true,
//         animationOptions: {
//             duration: 550,
//             easing: 'linear',
//             queue: false
//         }
//     }
//
// });
// $masonryPostsContainer.imagesLoaded().progress( function() {
//     masonrycontainer.masonry('layout');
// });








window.Alpine = Alpine;
// Start Alpine.js
Alpine.start();
/* End Import Alpine.js */



// Vue router
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            // Home page route
            path: '/:local',
            components: {
                postsComponent: () => import('./components/Posts/GetPostsComponent.vue'),
                sliderComponent: () => import('./components/Posts/GetSliderPostsComponent.vue'),
            }
        },
        {
            // Mediatheque page route
            path: '/:local/mediatheque',
            component: () => import('./components/Pages/GetMediathequeAssetsComponent.vue')
        },
        {
            // Press Releases page route
            path: '/:local/communiques-de-presse',
            component: () => import('./components/Posts/GetPostsByCategoryComponent.vue'),
            props: {
                catParentId: 7778, // Parent cat Id
            }
        },
        {
            // Press Kits page route
            path: '/:local/dossiers-de-presse',
            component: () => import('./components/Posts/GetPostsByCategoryComponent.vue'),
            props: {
                catParentId: 685, // Parent cat Id
                paginationType: 'numeric',
            }
        },
        {
            // Single post route
            path: '/:local/post/:single',
            component: () => import('./components/Posts/GetPostAssetsComponent.vue'),
        },
        {
            // Category page route
            path: '/:local/category/:category',
            component: () => import('./components/Posts/GetPostsByCategoryComponent.vue'),
            props: {
                paginationType: 'numeric',
            }
        },
        {
            // Selection page route
            path: '/:pathMatch(.*)*',
            component: () => import('./components/Pages/GetCartComponent'),
            props: true,
        },
        {
            // Search page route
            path: '/:local/search',
            component: () => import('./components/Search/SearchAllDataComponent'),
            props: true,
        },

        {
            // Overview page route
            path: '/:local/overview',
            component: () => import('./components/Posts/GetPostsFilteredByCategoryAndTagComponent.vue'),
            props: {
                catParentId: 7766
            },
        },

        {
            // Customers page route
            path: '/:local/customers',
            component: () => import('./components/Posts/GetClasseurComponent.vue'),
            props: {
                catParentId: 7805
            },
        },

    ]
});

// Check if the #app element exists in the DOM
const appElement = document.querySelector('#app');
if (appElement) {
    const app = createApp({});
    app.use(router);
    app.mount('#app');

}
// cartApp
const cartApp = createApp({});
const cartCounterAppElement = document.getElementById('cart-counter-app');
if (cartCounterAppElement) {
    cartApp.component('cart-counter', CartCounterComponent);
    cartApp.mount('#cart-counter-app'); // Mount to the specific element
}
