// jQuery = require('jquery');
// jQuery(function ($) {
//
//     let $openMenu = $('#open-menu');
//     let $closeMenu = $('#close-menu');
//     $openMenu.on('click', function(){
//         $('.header__container--navigation').show();
//         $openMenu.removeClass('show');
//         $closeMenu.addClass('show');
//     });
//     $closeMenu.on('click', function(){
//         $('.header__container--navigation').hide();
//         $openMenu.addClass('show');
//         $closeMenu.removeClass('show');
//     });
//
//     let $menuItemSubmenuTrigger = $('.menu__item > a > svg');
//
//     $menuItemSubmenuTrigger.on('click', function(e){
//
//         e.preventDefault();
//         e.stopPropagation();
//
//         $(this).parent().next('.submenu').slideToggle(150);
//
//     });
//
//
//     // let $submenuLink = $('.submenu__list--item > a');
//     // $submenuLink.each(function(){
//     //     $(this).on('click', function(){
//     //
//     //         alert("submenu link clicked");
//     //
//     //         let linkTo = $(this).attr('href');
//     //         window.location.href = $(this).attr(linkTo);
//     //
//     //     })
//     // })
//
//
//
// });
//
