<template>
    <section class="home-slider slider">
        <div class="slider-container swiper-container">
            <div class="slider-row swiper-wrapper">
                <div v-for="post in posts" class="slider-item swiper-slide">
                    <div class="slider-item--image">
                        <img class="swiper-lazy" :src="post.headline.large" alt="">
                        <div :class="['swiper-lazy-preloader', { 'hide-loader': !loading }]"></div>
                    </div>
                    <div class="slider-item--infos">
                        <div class="slider-item--title" v-html="post.title">
                        </div>
                        <div class="slider-item--cta">
                            <a :href="post.url" class="cta_white">En savoir plus</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slider-navigation">
                <button class="slider-navigation--prev swiper-button-prev"></button>
                <button class="slider-navigation--next swiper-button-next"></button>
            </div>
            <div class="slider-pagination swiper-pagination"></div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

export default {
    props: {
        tagSlug: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            posts: [],
            page: 1,
            per_page: 6,
            lang: document.body.getAttribute('data-lang'),
            loading: true,
            sliderInstance: null,
        };
    },
    mounted() {
        this.setupAjax();
        this.fetchTags();
    },
    methods: {
        setupAjax() {
            axios.defaults.headers.common['X-CSRF-TOKEN'] = document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute('content');
        },
        async fetchTags() {
            try {
                const response = await axios.get('/api/tags/'+this.lang);
                const tags = response.data;
                const tag = tags.find((tag) => tag.slug === this.tagSlug);

                if (tag) {
                    this.tag = tag.id;
                    this.fetchPosts();
                } else {
                    console.error('Tag not found');
                }
            } catch (error) {
                console.error('Error fetching tags:', error);
            }
        },
        fetchPosts() {
            axios
                .post('/ajax', {
                    lang: this.lang,
                    tag: this.tag,
                    page: this.page,
                    per_page: this.per_page,
                })
                .then((response) => {
                    //console.log(response);
                    if (response.data.datas !== 'no_data') {
                        this.posts = response.data.datas;
                    } else {
                        this.posts = [];
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.updateSwiper();
                });
        },
        updateSwiper() {
            if (this.sliderInstance) {
                this.sliderInstance.destroy();
            }
            this.sliderInstance = new Swiper('.slider-container', {

                speed: 1500,
                loop: true,
                allowTouchMove: true,
                autoplay: {
                    delay: 8888,
                },
                lazy: { enabled: true },
                navigation: {
                    nextEl: '.slider-navigation--next',
                    prevEl: '.slider-navigation--prev',
                },
                pagination: {
                    el: '.slider-pagination',
                    clickable: true,
                },
            });
        },
    },
};
</script>

<style scoped>

</style>
