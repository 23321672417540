<template>
    <div>
        <a href="/selection" className="selection_opener">
            <span id="cart-counter" className="count_items">{{ cartLength }}</span>
<!--            <img src="/img/header/cart.svg" alt="Selection icon">-->
            <svg class="cart-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.3939 17.0809L12 16.912L11.6061 17.0809L6 19.4835V5C6 4.45228 6.45228 4 7 4H17C17.5477 4 18 4.45228 18 5V19.4835L12.3939 17.0809Z" stroke="black" stroke-width="2"/>
            </svg>
        </a>
    </div>
</template>

<script>
import {cartMixin} from '../../modules/cartMixin.js';

export default {
    mixins: [cartMixin],

    computed: {
        cartLength() {
            return this.cartItems.length;
        }
    },

    methods: {
        addToOrRemoveFromCart(post) {
            if (this.isInCart(post)) {
                this.removeFromCart(post);
            } else {
                this.addToCart(post);
            }
            this.updateCartLength(); // Update cart length dynamically
        },
        updateCartLength() {
            const cartCounter = document.getElementById('cart-counter');
            if (cartCounter) {
                // Dispatch a custom event to signal the cart length change
                const event = new CustomEvent('cartUpdated', {detail: this.cartLength});
                cartCounter.dispatchEvent(event);
            }
        }
    },

    mounted() {
        // Listen for the custom event to update the cart length
        const cartCounter = document.getElementById('cart-counter');
        if (cartCounter) {
            cartCounter.addEventListener('cartUpdated', (event) => {
                cartCounter.textContent = event.detail;
            });
        }
    }
};
</script>
