import Swiper from "swiper";
window.$ = window.jQuery = require('jquery');

jQuery(function ($) {
    var $body = $('body');

    if($('.slider').length > 0){
        var slider = new Swiper('.slider-container', {
            speed: 1500,
            loop: true,
            allowTouchMove: true,
            autoplay: {
                delay: 8888,
            },
            navigation: {
                nextEl: ".slider-navigation--next",
                prevEl: ".slider-navigation--prev",
            },
            pagination: {
                el: ".slider-pagination",
                clickable: true
            },
        });
    }




    const swiperTestimonials = new Swiper('.swiper-testimonials', {
        effect: 'fade',
        loop: true,
        autoplay: {
            delay: 6000,
            pauseOnMouseEnter: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });


    const swiperActions = new Swiper(".swiper-actions", {
        loop: false,
        slidesPerView: 'auto',
        slidesPerGroupAuto: 1,
        freeMode: true,
    });
    var firstGrabDetected = false;
    swiperActions.on('touchStart', function() {
        if (!firstGrabDetected) {
            firstGrabDetected = true;
            $('.swiper-actions .swiper-slide').addClass('no-cursor');
        }
    });
    $body.on('click', '.swiper-actions .swiper-slide', function(e) {
        $(this).toggleClass('is-active');
    });

    const swiperPublisher = new Swiper('.swiper-publisher', {
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });

    const swiperWeAreEpp = new Swiper('.swiper-we-are-epp', {
        effect: 'fade',
        loop: true,
        autoplay: {
            delay: 6000,
            pauseOnMouseEnter: true,
        },
        navigation: {
            nextEl: '.swiper-button-next-we-are-epp',
            prevEl: '.swiper-button-prev-we-are-epp',
        },
        pagination: {
            el: '.swiper-pagination-we-are-epp',
            clickable: true
        }
    });

    const swiperChiffreCles = new Swiper('.swiper-chiffres-cles', {
        effect: 'fade',
        loop: true,
        autoplay: {
            delay: 6000,
            pauseOnMouseEnter: true,
        },
        navigation: {
            nextEl: '.swiper-button-next-chiffres',
            prevEl: '.swiper-button-prev-chiffres',
        },
        pagination: {
            el: '.swiper-pagination-chiffres',
            clickable: true
        }
    });



});
