<template>

    <section class="theme-main__container--section section-filters">
        <div class="max-width-theme">
            <div v-if="showFilters" class="filters">
                <!-- Get Categories -->
                <div class="filters-categories">
                    <label for="categories-select">Categories</label>
                    <vue-select
                        id="categories-select"
                        class="dropdown-select"
                        multiple
                        v-model="selectedCats"
                        :options="categories"
                        label="label"
                        @input="applyFilters"
                        placeholder="Select a category"
                    />
                </div>
                <!-- Get Tags -->
                <div class="filters-tags">
                    <label for="tags-select">Tags</label>
                    <vue-select
                        id="tags-select"
                        class="dropdown-select"
                        multiple
                        v-model="selectedTags"
                        :options="tags"
                        label="label"
                        @input="applyFilters"
                        placeholder="Select a tag"
                    />
                </div>
                <!-- Get Date Start -->
                <div class="filters-date-begin">
                    <label for="date_begin">start</label>
                    <input type="date" id="date_begin" name="date_begin" v-model="date_begin" @change="handleBeginDateChange($event.target.value)" />
                </div>
                <!-- Get Date End -->
                <div class="filters-date-end">
                    <label for="date_end">end</label>
                    <input type="date" id="date_end" name="date_end" v-model="date_end" @change="handleEndDateChange($event.target.value)" />
                </div>
                <!-- Get Keyword -->
                <div class="filters-search">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg>
                    <label for="search_keyword" class="hide"></label>
                    <input type="text" id="search_keyword" name="search_keyword" placeholder="Mot clé" v-model="keyword"/>
                </div>
                <!-- Get current lang -->
                <input type="hidden" name="lang" id="lang" :value="lang" />
                <div class="filters-submit">
                    <button @click="applyFilters">Submit</button>
                </div>
            </div>
        </div>
    </section>

    <section class="theme-main__container--section section-flux">
        <div class="max-width-theme">
            <div class="flux-items">
                <!-- Loop on posts -->
                <article v-if="posts.length > 0" v-for="post in posts" :key="post.id" class="post-card">
                    <a :href="post.url" class="post-card__image">
                        <img class="image" :src="post.headline.medium" alt="Vignette thumbnail" />
                    </a>
                    <div class="post-card__content">
                        <div v-if="post.categories" class="post-card__content--categories">
                            {{ post.categories[0] }}
                        </div>
                        <h3 class="post-card__content--title" v-html="post.title.length > 100 ? post.title.replace( /(<([^>]+)>)/ig, '').substring(0, 100) + '...' : post.title.replace( /(<([^>]+)>)/ig, '')"></h3>
                        <p class="post-card__content--excerpt" v-html="post.content.length > 200 ? post.content.replace( /(<([^>]+)>)/ig, '').substring(0, 200) + '...' : post.content.replace( /(<([^>]+)>)/ig, '')"></p>
                    </div>
                    <div class="post-card__cta">
                        <a :href="post.url">
                            {{ lang == 'eng' ? 'Learn more' : 'En savoir plus' }}
                        </a>
                    </div>
                </article>

            </div>
        </div>
    </section>

    <section class="theme-main__container--section section-ctas">
        <div class="max-width-theme">
            <div class="container-post">
            <!-- Numeric pagination -->
            <div v-if="paginationType === 'numeric' && showNumericPagination">
                <div id="loading" v-if="loading">
                    <span>Processing...</span>
                </div>
                <div v-else-if="posts.length > 0" class="pagination">
                    <ul class="pagination_items">
                        <li v-if="page > 1" @click="changePage(page - 1)">&laquo;</li>
                        <li v-for="pageNumber in displayedPages" :key="pageNumber" @click="changePage(pageNumber)" :class="{ active: pageNumber === page }">
                            {{ pageNumber }}
                        </li>
                        <li v-if="page < totalPages" @click="changePage(page + 1)">&raquo;</li>
                    </ul>
                </div>
                <div v-else>
                    <span v-if="showNoDataMessage">No data available</span>
                </div>
            </div>
            <!-- Loadmore pagination -->
            <button v-else-if="showLoadMoreButton" @click="loadMorePosts" id="load_more_post" class="load_more">
                <span class="border"></span>
                <span class="text">
                    <span v-if="loading">Loading...</span>
                    <span v-else-if="posts && posts.length === 0">No data</span>
                    <span v-else>Load More</span>
                </span>
            </button>
            <div v-if="showNoPostsMessage">
                <div id="no_data">No data available.</div>
            </div>
        </div>
        </div>
    </section>

</template>

<script>
import axios from 'axios';
import VueSelect from 'vue-select';
export default {
    components: {
        VueSelect, // Register the VueSelect component
    },
    data() {
        return {
            posts: [],           // Array for posts
            categories:[], // // Array for categories
            tags:[], // Array for tags
            selectedCats: [],
            selectedTags: [],
            cat: [], // Selected categories (array)
            tag: [], // Selected tags (array)
            keyword: '', // Selected keyword
            date_begin: '', // Selected start date
            date_end: '', // Selected end date
            loading: true,       // loading activated
            page: 1,             // Current Page
            per_page: 6,         // Per page = 6
            lang: document.body.getAttribute('data-lang'),           // Current lang
            showLoadMoreButton: true, // showLoadMoreButton init
            showNumericPagination: true, // showNumericPagination int
            paginationType: '', // Add the paginationType parameter
            totalPages: 0,            // Total number of pages
            displayedPages: [],       // Array of page numbers to be displayed
            currentPage: 1, // Current active pagination item
            showNoPostsMessage: false,
            showNoDataMessage: false,
            showFilters: false,
        };
    },
    created(){
        this.fetchCategories();
        this.fetchTags();
    },
    mounted() {
        this.setupAjax();
        this.fetchPosts();
    },
    methods: {
        setupAjax() {
            // Configuration of request headers for axios
            axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        },
        fetchPosts(cat = null, tag = null, keyword = null, date_begin = null, date_end = null) {
            // Concatenate the selected category IDs with "-"
            const catIds = this.selectedCats.map(cat => cat.id);
            const concatenatedStringCatArray = catIds.join('-');
            this.cat = concatenatedStringCatArray;
            const tagIds = this.selectedTags.map(tag => tag.id);
            const concatenatedStringTagArray = tagIds.join('-');
            this.loading = true;
            if (cat !== null) {
                this.cat = concatenatedStringCatArray;
            }
            if (tag !== null) {
                this.tag = concatenatedStringTagArray;
            }
            if (keyword !== null) {
                this.keyword = keyword;
            }
            if (date_begin !== null) {
                this.date_begin = date_begin;
            }
            if (date_end !== null) {
                this.date_end = date_end;
            }

            // Send a request to fetch the posts
            axios
                .post('/ajax', {
                    lang: this.lang,
                    cat: this.cat,
                    tag: this.tag,
                    keyword: this.keyword,
                    date_begin: this.date_begin,
                    date_end: this.date_end,
                    page: this.page,
                    per_page: this.per_page,
                })
                .then((response) => {
                    this.loading = false; // Hide the loading message
                    this.showLoadMoreButton = true;
                    this.showNumericPagination = true;
                    if (response.data.datas !== 'no_data') {
                        // Update the list of posts
                        this.posts = response.data.datas;
                        // console.log("filter", response)
                        this.totalPages = response.data.last_page;
                        this.displayedPages = this.calculateDisplayedPages();
                        if (parseInt(response.data.current_page) >= parseInt(response.data.last_page)) {
                            this.showLoadMoreButton = false;
                            this.showNoDataMessage = false; // Reset the flag
                        }
                    }else{
                        // Handle no data case
                        this.posts = [];
                        this.totalPages = 0;
                        this.displayedPages = [];
                        this.showLoadMoreButton = false;
                        this.showNumericPagination = false;
                        this.showNoDataMessage = true; // Reset the flag
                        this.showNoPostsMessage = true;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                });
        },
        loadMorePosts() {
            if (this.paginationType === 'numeric') {
                this.page += 1;
                this.fetchPosts(this.cat, this.tag, this.keyword, this.date_begin, this.date_end, this.page);
            } else {
                this.page += 1;
                this.loading = true;
                axios
                    .post('/ajax', {
                        cat: this.cat,
                        tag: this.tag,
                        keyword: this.keyword,
                        date_begin: this.date_begin,
                        date_end: this.date_end,
                        page: this.page,
                        lang: this.lang,
                        per_page: this.per_page,
                    })
                    .then((response) => {
                        if (response.data.datas !== 'no_data') {
                            this.posts = this.posts.concat(response.data.datas);
                            if (parseInt(response.data.current_page) < parseInt(response.data.last_page)) {
                                this.showLoadMoreButton = true;
                                this.showNumericPagination = true;
                                this.loading = false;
                            } else {
                                this.showLoadMoreButton = false;
                                this.showNumericPagination = false;
                                this.loading = false;
                            }
                        } else {
                            this.showLoadMoreButton = false;
                            this.showNumericPagination = false;
                            this.loading = false;
                        }

                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error(error);
                        this.loading = false;
                    });
            }
        },
        fetchCategories() {
            axios.defaults.headers.common['X-API-Key'] = '4J8sIeD6k2w9jGyP';
            axios.defaults.headers.common['X-API-Secret'] = '3FhRt7L2mXp5vQcS';
            axios
                .get('/api/categories/'+this.lang, {

                })
                .then(response => {
                    // console.log("cat from api", response)
                    if (response.data) {
                        this.categories = response.data
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.error(error);
                });
        },
        fetchTags() {
            axios.defaults.headers.common['X-API-Key'] = '4J8sIeD6k2w9jGyP';
            axios.defaults.headers.common['X-API-Secret'] = '3FhRt7L2mXp5vQcS';
            axios
                .get('/api/tags/'+this.lang, {

                })
                .then(response => {
                    if (response.data) {
                        this.tags = response.data
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.error(error);
                });
        },
        applyFilters() {
            this.loading = true; // Show the loader
            this.showLoadMoreButton = true;
            this.showNoPostsMessage = false;
            this.page = 1; // Reset the page to 1
            this.posts = []; // Reset the posts array
            const catIds = this.selectedCats.map(cat => cat.id);
            const concatenatedStringCatArray = catIds.join('-');
            this.cat = concatenatedStringCatArray;
            const tagIds = this.selectedTags.map(tag => tag.id);
            const concatenatedStringTagArray = tagIds.join('-');
            this.tag = concatenatedStringTagArray;
            this.fetchPosts(this.cat, this.tag, this.keyword, this.date_begin, this.date_end);
            this.showNumericPagination = false;
        },
        handleBeginDateChange(dateBegin) {
            if (dateBegin) {
                const dateObj = new Date(dateBegin);
                const formattedDate = dateObj.toISOString().split('T')[0];
                this.date_begin = formattedDate;
            } else {
                this.date_begin = '';
            }
        },
        handleEndDateChange(dateEnd) {
            if (dateEnd) {
                const dateObj = new Date(dateEnd);
                const formattedDate = dateObj.toISOString().split('T')[0];
                this.date_end = formattedDate;
            } else {
                this.date_end = '';
            }
        },
        changePage(pageNumber) {
            if (pageNumber > 0 && pageNumber <= this.totalPages) {
                this.page = pageNumber;
                this.fetchPosts();
            }
        },
        calculateDisplayedPages() {
            const totalPages = this.totalPages;
            const currentPage = this.page;
            const slots = 3; // Number of slots between numbers
            const startSlot = currentPage - slots > 1 ? currentPage - slots : 1;
            const endSlot = currentPage + slots < totalPages ? currentPage + slots : totalPages;
            let displayedPages = [];
            if (startSlot > 1) {
                displayedPages.push(1); // Always show the first page
                if (startSlot > 2) {
                    displayedPages.push('...'); // Show ellipsis if there is a gap before the start slot
                }
            }
            for (let i = startSlot; i <= endSlot; i++) {
                displayedPages.push(i); // Add page numbers within the start and end slots
            }
            if (endSlot < totalPages) {
                if (endSlot < totalPages - 1) {
                    displayedPages.push('...'); // Show ellipsis if there is a gap after the end slot
                }
                displayedPages.push(totalPages); // Always show the last page
            }
            return displayedPages;
        },
    }
};
</script>

<style>
/* Styles CSS */
@import '~vue-select/dist/vue-select.css';
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.pagination li {
    margin: 0 5px;
}
.pagination .active {
    font-weight: bold;
}
.filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}

.filters > div {
    margin: 10px;
}

.dropdown-select {
    width: 200px;
}

.filters-date-begin input,
.filters-date-end input {
    width: 150px;
    height: 30px;
}

.filters-search {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.filters-search svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.filters-submit {
    margin-left: 10px;
}

</style>
