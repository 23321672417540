
import '../app';
import { createApp } from 'vue';
import GetSliderPostsComponent from '../components/Posts/GetSliderPostsComponent';
import GetPostsComponent from '../components/Posts/GetPostsComponent';
import CartCounterComponent from "../components/Pages/GetCartCounterComponent";

const createAppInstances = () => {
    // sliderApp
    const sliderApp = createApp({});
    const sliderAppElement = document.getElementById('home_slider');
    if (sliderAppElement) {
        sliderApp.component('home-slider', GetSliderPostsComponent);
        sliderApp.mount('#home_slider'); // Mount to the specific element
    }

    // postsApp
    const postsApp = createApp({});
    const postsComponentElement = document.getElementsByClassName('postsComponent');
    if (postsComponentElement && postsComponentElement.length > 0) {
        postsApp.component('posts-component', GetPostsComponent);
        postsApp.mount('.postsComponent'); // Mount to the specific elements with class "postsComponent"
    }


};

createAppInstances();
