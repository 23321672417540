jQuery(function ($) {

    let customersCatsElements = document.querySelectorAll('.filter-customer-cat');
    let customersCatsArray = [];


    for(let i=0; i <= customersCatsElements.length; i++){
        customersCatsArray.push(customersCatsElements[i]);
    }

    // console.log(customersCatsArray);



});
